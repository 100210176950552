.footer {
  width: 100%;
  padding: 2%;
  padding-bottom: 0;
}

.footer-buttons {
  display: flex;
  justify-content: center;

  padding: 1%;
}
