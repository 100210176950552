.media-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.media-card-img {
  object-fit: contain !important;
  padding: 8px;
}
