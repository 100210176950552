.title-section {
  position: relative;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: -24px;
  height: 240px;
}

#gradient-canvas {
  --gradient-color-1: var(--gradient-color-choice-1);
  --gradient-color-2: var(--gradient-color-choice-2);
  --gradient-color-3: var(--gradient-color-choice-3);
  --gradient-color-4: var(--gradient-color-choice-4);

  width: 100%;
  height: 100%;
  position: absolute;
}

.title-text-wrapper {
  padding: 48px;
  position: absolute;

  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 100%;
  height: 100%;
}

.title-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.main-title {
  margin: 0;
  font-weight: unset;
}

.main-title span {
  font-weight: bold;
}

.title-image {
  margin-left: 5%;
}

@media (max-width: 599px) {
  .title-image {
    display: none;
  }
}

@media (max-height: 750px) {
  .title-image {
    display: none;
  }
}

.intro div {
  padding: 2%;
}

.intro-text {
  font-size: 1.1rem;
}

.about-me-buttons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 !important;

  column-gap: 16px;
  row-gap: 16px;
}

.skills {
  text-align: center;
  padding: 0;
  /* max-width: 800px; */
  margin: auto;
  padding: 16px;
}

.skills-wrapper {
  text-transform: none !important;
  margin: 5px !important;
  color: white !important;

  display: inline-flex;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;

  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 24px;
  padding: 0 16px;
  min-width: 48px;
  width: auto;
  height: 48px;
  background-color: #29b6f6;
}
