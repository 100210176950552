.grid-item {
  padding: 20px !important;
}

.card-action-area {
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  height: 100%;
}

.card-details-container {
  flex: 1;
  height: 100%;
}

.card-details {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.position {
  margin-bottom: unset !important;
}

.company-image-container {
  width: 160px;
  margin: 5%;
}

.company-image {
  width: 100%;
  /* background: white; */
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10%;
}

.tags button {
  text-transform: none;
  pointer-events: none;
}
